






















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import "@/types";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  components: {
    Confirm
  },

  metaInfo() {
    return {
      title: "Eğitimleri Yönet"
    };
  },

  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "name"
        },
        { text: "Durum", value: "disabled", width: "90px" },
        { text: "Tarih", value: "startDate", width: "220px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],

      course: {
        id: "",
        code: "",
        urlCode: "",
        name: "",
        desc: "",
        img: "",
        instructor: "",
        startDate: new Date(),
        finishDate: null,
        price: 0,
        disabled: false
      } as Course,

      lessons: [] as Lesson[]
    };
  },

  computed: {
    breadcrumbs(): any[] {
      return [
        {
          text: "Eğitimler",
          exact: true,
          to: { name: "admin-courses" }
        },
        {
          text: this.course.name,
          exact: true,
          to: { name: "admin-course-edit", params: { id: this.course.id } }
        },
        {
          text: "Dersler"
        }
      ];
    }
  },

  methods: {
    async getCourse() {
      try {
        // Get course to database
        const qs = await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${this.$route.params.id}`)
          .get();

        if (qs.exists) {
          const newCourse = {} as Course;
          newCourse.id = qs.id;
          newCourse.name = qs.data()?.name;
          newCourse.urlCode = qs.data()?.urlCode;
          newCourse.disabled = qs.data()?.disabled;
          newCourse.instructor = qs.data()?.instructor;
          newCourse.desc = qs.data()?.desc;
          newCourse.img = qs.data()?.img;
          newCourse.price = qs.data()?.price;
          newCourse.startDate = qs.data()?.startDate
            ? qs.data()?.startDate.toDate()
            : null;
          newCourse.finishDate = qs.data()?.finishDate
            ? qs.data()?.finishDate.toDate()
            : null;

          this.course = newCourse;
        }
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: `Eğitim bilgilerini alınamadı.`
        });

        throw new Error(`Eğitim bilgileri alınamadı. ${err.message}`);
      }
    },

    async getLessons() {
      this.loading = true;

      try {
        // Get all lessons
        const query = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/lessons")
          .where("courseId", "==", this.course.id)
          .orderBy("startDate")
          .get();

        this.lessons = [];
        query.forEach(doc => {
          if (doc.exists) {
            const lesson = {
              id: doc.id,
              name: doc.data().name,
              startDate: doc.data().startDate.toDate(),
              desc: doc.data().desc,
              disabled: doc.data().disabled,
              courseId: doc.data().courseId,
              zoomLink: doc.data().zoomLink,
              youtubeId: doc.data().youtubeId
            } as Lesson;

            this.lessons.push(lesson);
          }
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Ders listesi alınamadı.`
        });
        throw new Error(`Eğitim bilgileri alınamadı. ${err.message}`);
      } finally {
        this.loading = false;
      }
    },

    async deleteLesson(lesson: Lesson) {
      const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
      const confirmation = await $confirm.open(
        "Emin misiniz?",
        "Bu işlem geri alınamaz. Dersi silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (!confirmation) return;

      try {
        // Dersi sil
        await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/lessons/${lesson.id}`)
          .delete();

        this.$notify({
          type: "success",
          title: "İşlem Tamam",
          text: "Ders başarıyla silindi."
        });

        await this.getLessons();
      } catch (err) {
        this.$notify({
          type: "eror",
          title: "Hata!",
          text: "Ders silinemedi."
        });

        throw new Error(`Ders silinemedi. ${err.message}`);
      }
    }
  },

  async mounted() {
    await this.getCourse();
    await this.getLessons();
  }
});
